(function () {
    angular.module('informaApp')
        .component('activityList', {
            templateUrl: 'components/admin-sections/usage-section/activity-list/template.html',
            controller: ActivityListController,
            bindings: {
                source: '<',
                onExportClick: '<'
            }
        });

    function ActivityListController() {
        this.headers = ['Company', 'User', 'Salesforce Id', 'Activity', 'Date and Time'];

        this.export = () => {
            if (this.onExportClick){
                this.onExportClick(this.headers);
            }
        };
    }
})();
